html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.enter {
  margin-top: 10px;
}

.ant-form {
  background-color: #f0f2f5;
  padding: 20px;
  padding-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.switch-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
