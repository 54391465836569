.posts {
    /* background-color: black; */
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    height: 100px;
}

.ant-input-textarea {
    resize: none; /* Отключаем изменение размера */
  }
  
  .ant-card p {
    word-wrap: break-word; /* Обрезаем длинный текст */
    white-space: pre-wrap; /* Сохраняем пробелы и переносы строк */
  }
